import { defineComponent } from 'vue'
import FirebaseUi from '@/components/FirebaseUi'


export default defineComponent({
    components: { FirebaseUi },
    setup() {
        return () => (
            <div
                style="width:100%;display:flex;justify-content:center;align-items:center;min-width: 1000px;min-height:100vh"
            >
                <div class="login-left" style="min-width: 500px;width:50%;padding-block:20px">
                    <div class="login" style="display: flex;justify-content: center;">
                        <div>
                            <img
                                style="margin-bottom: 60px;max-height:100px;"
                                src={require('@/assets/login.png')}
                                alt="dsflow logo"
                            />
                            <FirebaseUi />
                        </div>
                    </div>
                </div>
                <div class="login-right" style="height: 100%;display: flex;justify-content: center;align-items: center;background: #eef1f5;width:50%">
                    <img
                        style="width:66%"
                        src={require('@/assets/hero.svg')}
                        alt="People working"
                    />
                </div>
            </div>
        )
    },
})