
import { defineComponent, ref } from 'vue'

import { signIn } from '@/services/auth'
import { Model } from '@/components/DsForm'

import {
    ElForm,
    ElFormItem,
    ElAlert,
    ElInput,
    ElButton,
    ElDivider
} from 'element-plus'

export default defineComponent({
    name: 'FirebaseUi',
    components: {
        ElForm, ElFormItem, ElAlert, ElInput, ElButton, ElDivider
    },
    setup() {
        const credentials = ref({
            email: '',
            password: ''
        })
        const loading = ref(false)
        const error = ref<string | void>('')
        const showResetPassword = ref(true)


        const formRef = ref<InstanceType<typeof ElForm>>()

        const validateAndSignIn = () => {
            formRef.value?.validate(async (valid) => {
                if (valid) {
                    loading.value = true
                    error.value = await signIn(credentials.value.email, credentials.value.password)
                    loading.value = false
                }
            })
        }

        return () => (
            <div>
                {showResetPassword.value ? (<div>
                    {/* onKeydown={(e) => e.key === "enter" ? alert() : null} */}
                    <h2 >Welcome back!</h2>
                    <ElForm
                        style="width: 350px;"
                        label-position="top"
                        ref={formRef}
                        model={credentials.value}
                    >
                        {error.value ? <ElAlert
                            class="login-alert"
                            title={error.value}
                            type="error"
                            closable={false}
                        ></ElAlert> : null}
                        <ElFormItem
                            label="Email address"
                            prop="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email address',
                                    trigger: 'blur'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address',
                                    trigger: 'blur'
                                }
                            ]}
                        >
                            <ElInput
                                modelValue={credentials.value.email}
                                onInput={(value) => credentials.value.email = value}
                                type="email"
                                placeholder="Please enter your email address"
                            />
                        </ElFormItem>
                        <ElFormItem
                            label="Password"
                            prop="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password',
                                    trigger: 'blur'
                                }
                            ]}
                        >
                            <ElInput
                                modelValue={credentials.value.password}
                                onInput={(value) => credentials.value.password = value}
                                type="password"

                                placeholder="Please enter your password"
                            />
                        </ElFormItem>
                        <div
                            style="display: flex; justify-content: space-between; margin-top: 20px;align-items: flex-start;"
                        >
                            <ElFormItem>
                                <ElButton type="primary" loading={loading.value} onClick={validateAndSignIn}>
                                    Log In
                                </ElButton>
                            </ElFormItem>
                            <ElButton
                                type="text"
                                onClick={() => showResetPassword.value = !showResetPassword.value}
                                class="forgot-password"
                            >
                                Forgot password?
                            </ElButton>
                        </div>
                    </ElForm>
                </div>) : (
                    <div>
                        <h2>Reset your password</h2>
                        <div style="width: 400px; margin-bottom: 12px;">
                            {/* vmodel here !!!!!! */}
                            <ElInput
                                type="email"
                                placeholder="Please enter your email address"
                            />
                            <div
                                style="display: flex; justify-content: space-between; margin-top: 20px;align-items: flex-start;"
                            >
                                {/* OnClick here TODOs */}
                                <ElButton
                                    type="primary">
                                    Send a link
                                </ElButton>
                                <ElButton
                                    type="text"
                                    onClick={() => showResetPassword.value = !showResetPassword.value}
                                    class="forgot-password"
                                >
                                    Go back to login
                                </ElButton>
                            </div>
                        </div>
                    </div>)}
                {/* <div>
                    <ElDivider></ElDivider>
                    <p class="open-account">
                        Don't have an account yet?
                        <a class="open-account-link" href="mailto:support@dsflow.io">
                            Open an account
                        </a>
                    </p>
                </div> */}
            </div >
        )
    }
})
